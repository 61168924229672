<template>
  <div>
    <div class="row">
      <div class="col-6 col-md-3 col-lg-2">
        <page-title />
      </div>
      <div class="col-6 col-md-3 col-lg-2 mt-2 mb-2">
        <b-button @click="forceSync()">
          Force Sync
        </b-button>
      </div>
    </div>
    <table-sort-mobile
      :sort-by.sync="sortBy"
      :sort-options="sortOptions"
      :sort-desc.sync="sortDesc"
    >
      <b-form-input
        v-model="filter.alias"
        class="mb-2"
        placeholder="Filter by alias"
        debounce="300"
        autofocus
      />
      <b-form-input
        v-model="filter.asin"
        class="mb-2"
        placeholder="Filter by asin"
        debounce="300"
      />
    </table-sort-mobile>
    <b-form-checkbox
      v-model="filter.isClosed"
      class="mr-2 mb-2 float-left"
      switch
    >
      Closed <span class="text-muted">({{ filter.isClosed === null ? 'Any' : (filter.isClosed ? 'Yes' : 'No') }})</span>
    </b-form-checkbox>
    <b-icon
      v-if="filter.isClosed !== null"
      icon="dash-circle"
      title="Reset filter"
      @click="filter.isClosed = null"
    />
    <div class="clearfix" />
    <b-table
      ref="table"
      :fields="fields"
      :items="itemsProvider"
      :current-page="currentPage"
      :per-page="perPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      api-url="/card/list"
      stacked="sm"
      show-empty
      no-local-sorting
      fixed
    >
      <template #head(alias)="data">
        {{ data.label }}
        <b-input
          v-model="filter.alias"
          size="sm"
          debounce="300"
          autofocus
        />
      </template>

      <template #head(asin)="data">
        {{ data.label }}
        <b-input
          v-model="filter.asin"
          size="sm"
          debounce="300"
        />
      </template>

      <template #head(isClosed)="data">
        <div>{{ data.label }}</div>
        <b-form-checkbox
          v-model="filter.isClosed"
          class="float-left mr-2"
          name="check-button"
          switch
        >
          <span class="text-muted">{{ filter.isClosed === null ? 'Any' : (filter.isClosed ? 'Yes' : 'No') }}</span>
        </b-form-checkbox>
        <b-icon
          v-if="filter.isClosed !== null"
          icon="dash-circle"
          title="Reset filter"
          @click="filter.isClosed = null"
        />
      </template>

      <template #cell(adminComment)="{ item, value }">
        <comment-input
          :comment="value"
          :card-id="item.id"
        />
      </template>

      <template #cell(actions)="{ item, toggleDetails, detailsShowing }">
        <b-button
          size="sm"
          class="mr-2"
          :disabled="loading"
          @click="item.isClosed ? showClosedCard(item.id) :showCardVote(item.id)"
        >
          {{ item.isClosed ? 'View' : 'Vote' }}
        </b-button>
        <b-button
          size="sm"
          variant="light"
          @click="toggleDetails"
        >
          {{ detailsShowing ? 'Hide voted' : 'Voted' }}
        </b-button>
      </template>

      <template #row-details="{ item }">
        <b-card>
          <b-table
            :items="votesProvider"
            :fields="votedFields"
            :per-page="100500"
            :api-url="`/card/${item.id}/votes`"
            stacked="sm"
            show-empty
            small
          >
            <template #cell(actions)="{ item: voice}">
              <b-button
                v-if="!item.isClosed"
                size="sm"
                @click="fastVote(item.id, voice)"
              >
                Vote
              </b-button>
            </template>
          </b-table>
        </b-card>
      </template>
    </b-table>
    <div>
      <table-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
      />
      <table-limit
        v-model="perPage"
        :total-rows="totalRows"
      />
    </div>

    <b-modal
      id="card-vote"
      :title="card.name"
      size="xl"
      hide-footer
    >
      <b-overlay :show="loading">
        <b-alert
          :show="!!error"
          variant="danger"
          v-text="error"
        />
        <card-vote
          v-if="card.id"
          :card="card"
          :show-revote-msg="false"
        >
          <template v-slot="{ canVote, voteAsin, voteProblem }">
            <b-button
              class="mr-2 mb-2"
              variant="primary"
              :disabled="!canVote"
              @click="vote(voteAsin, voteProblem)"
            >
              <b-spinner
                v-if="voting"
                small
              />
              Vote
            </b-button>
            <b-button
              class="mr-2 mb-2"
              @click="hideCardVote()"
            >
              Cancel
            </b-button>
          </template>
        </card-vote>
      </b-overlay>
    </b-modal>
    <b-modal
      id="card-closed"
      :title="closedCard.name"
      size="xl"
      ok-only
    >
      <b-overlay :show="loading">
        <b-alert
          :show="!!error"
          variant="danger"
          v-text="error"
        />
        <card-closed :card="closedCard" />
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle'
import table from '@/mixins/table'
import TableSortMobile from '@/components/TableSortMobile'
import ApiService from '@/ApiService'
import { getRequestErrorText } from '@/utils'
import CardVote from '@/components/CardVote'
import CommentInput from '@/components/CommentInput'
import TablePagination from '@/components/TablePagination'
import TableLimit from '@/components/TableLimit'
import CardClosed from '@/components/CardClosed'

export default {
  name: 'Cards',
  components: {
    TableLimit,
    TablePagination,
    CommentInput,
    CardVote,
    TableSortMobile,
    PageTitle,
    CardClosed
  },
  mixins: [table],
  data () {
    return {
      fields: [
        {
          key: 'alias',
          sortable: true
        },
        {
          key: 'name',
          tdClass: 'card-name'
        },
        {
          key: 'asin',
          sortable: true
        },
        {
          key: 'seller'
        },
        {
          key: 'adminComment',
          label: 'Comment'
        },
        {
          key: 'actions'
        }
      ],
      votedFields: [
        {
          key: 'user'
        },
        {
          key: 'asin'
        },
        {
          key: 'actions'
        },
        {
          key: 'problem'
        }
      ],
      filter: {
        alias: '',
        asin: '',
        isClosed: false
      },
      card: {},
      loading: false,
      error: '',
      voting: false,
      votes: [],
      closedCard: {}
    }
  },
  methods: {
    fetchCard (id) {
      this.loading = true
      this.error = ''

      ApiService.get(`/card/${id}`)
        .then(response => {
          this.card = response.data
        })
        .catch(error => {
          this.error = getRequestErrorText(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    showClosedCard (id) {
      this.$bvModal.show('card-closed')
      this.fetchClosedCard(id)
    },
    fetchClosedCard (id) {
      this.loading = true
      this.error = ''

      ApiService.get(`/card/${id}/closed/view`)
        .then(response => {
          this.closedCard = response.data
        })
        .catch(error => {
          this.error = getRequestErrorText(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    showCardVote (id) {
      this.$bvModal.show('card-vote')
      this.fetchCard(id)
    },
    hideCardVote () {
      this.$bvModal.hide('card-vote')
    },
    vote (voteAsin, voteProblem) {
      this.voteInternal(this.card.id, voteAsin, voteProblem)
    },
    fastVote (cardId, voice) {
      this.voteInternal(cardId, voice.asin, voice.problem)
    },
    voteInternal (cardId, asin, problem) {
      this.voting = true

      ApiService.post(`/card/${cardId}`, {
        asin: asin,
        problem: problem
      })
        .then(() => {
          this.refresh()
          this.hideCardVote()
        })
        .catch(error => {
          this.$bvToast.toast(getRequestErrorText(error), {
            title: 'Vote error',
            variant: 'danger'
          })
        })
        .finally(() => {
          this.voting = false
        })
    },
    votesProvider (ctx, callback) {
      let items = []

      ApiService.get(ctx.apiUrl)
        .then(response => {
          items = response.data

          for (const item of items) {
            if (item.first) {
              item._rowVariant = 'info'
            }
          }

          callback(items)
        })
        .catch(error => {
          this.$bvToast.toast(getRequestErrorText(error), {
            title: 'Load votes error',
            variant: 'danger'
          })

          callback(items)
        })

      return null
    },
    forceSync () {
      ApiService.post('/card/send/force')
        .then(() => {
          this.$bvToast.toast('Force sync started', {
            title: 'Success',
            variant: 'success'
          })
        })
        .catch(error => {
          this.$bvToast.toast(getRequestErrorText(error), {
            title: 'Force sync error',
            variant: 'danger'
          })
        })
        .finally(() => {
          this.saving = false
        })
    }
  }
}
</script>

<style>
.card-name div {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
