<template>
  <b-input-group size="sm">
    <b-form-input
      :value="comment"
      @input="newComment = $event"
    />
    <b-input-group-append v-if="newComment !== null && comment !== newComment">
      <b-button
        variant="info"
        :disabled="saving"
        @click="save()"
      >
        <b-icon
          v-if="!saving"
          icon="check"
        />
        <b-spinner
          v-else
          small
        />
      </b-button>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import ApiService from '@/ApiService'
import { getRequestErrorText } from '@/utils'

export default {
  name: 'CommentInput',
  props: {
    comment: {
      type: String,
      required: true
    },
    cardId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      newComment: null,
      saving: false
    }
  },
  methods: {
    save () {
      this.saving = true

      ApiService.post(`/card/${this.cardId}/comment`, { comment: this.newComment })
        .then(() => {
          this.newComment = null
        })
        .catch(error => {
          this.$bvToast.toast(getRequestErrorText(error), {
            title: 'Save comment error',
            variant: 'danger'
          })
        })
        .finally(() => {
          this.saving = false
        })
    }
  }
}
</script>
