<template>
  <div>
    <ul class="list-group">
      <li
        v-if="card.adminComment"
        class="list-group-item"
      >
        admin comment: <span class="font-weight-bold text-warning">{{ card.adminComment }}</span>
      </li>
    </ul>
    <ul class="list-group list-group-horizontal text-center mt-2 list-group-condensed">
      <li class="list-group-item flex-fill">
        alias<br><span class="font-weight-bold">{{ card.alias }}</span>
      </li>
      <li class="list-group-item flex-fill">
        qty<br><span class="font-weight-bold text-danger">{{ card.qty }}</span>
      </li>
      <li class="list-group-item flex-fill">
        weight<br><span class="font-weight-bold">{{ card.weight }}</span>
      </li>
    </ul>
    <ul class="list-group mt-2">
      <li class="list-group-item">
        from: <span class="font-weight-bold">{{ card.from }}</span>
      </li>
      <li class="list-group-item">
        comment: <span class="font-weight-bold">{{ card.comment }}</span>
      </li>
    </ul>
    <card-images
      v-if="card.images"
      class="my-3"
      :images="card.images"
    />
    <p class="mt-2">
      Answer: <span
        class="font-weight-bold"
        v-text="card.answer"
      />
    </p>
  </div>
</template>

<script>
import CardImages from '@/components/CardImages'
export default {
  name: 'CardClosed',
  components: { CardImages },
  props: {
    card: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.list-group-condensed .list-group-item {
  padding: .1rem .1rem;
}
</style>
